import React from "react";
import { graphql } from "gatsby";
import { Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import Layout from "../components/layout";

import Title from "../components/Title";
import Game from "../components/Game";
import Seo from "../components/seo";

const Games = ({ data, location, path }) => {
  const posts = data.allContentfulGamePost.nodes;
  return (
    <Layout location={location} path={path}>
      <Seo title="Game Designer" />
      <Row className="pt-5">
        <Title title="My Games" position="center" pageTitle />
      </Row>
      {posts.map((post, idx) => {
        return <Game post={post} key={idx} />;
      })}
    </Layout>
  );
};

export default Games;

export const data = graphql`
  query GameQuery {
    allContentfulGamePost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        gamePreviewContent {
          raw
        }
        gameContent {
          raw
        }
        childContentfulGamePostFirstTextTextNode {
          childMarkdownRemark {
            html
          }
        }
        itchIoEmbedNumber
        itchIoLink
        itchIoLinkTitle
      }
    }
  }
`;
