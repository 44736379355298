import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import variables from "../contants/variables";

import Button from "./Button";
import Itch from "./Itch";

const Game = ({ post }) => {
  const image = post.heroImage.gatsbyImageData;
  const gamePreviewContent = post.gamePreviewContent;
  const itchNumber = post.itchIoEmbedNumber;

  return (
    <StyledRow className="d-flex justify-content-center px-xl-5">
      <Row className="d-flex flex-column flex-md-row">
        <Col md={12} className="my-5">
          <h2 className="text-center text-md-start">{post.title}</h2>
        </Col>
        <Col md={6} className="my-auto">
          {renderRichText(gamePreviewContent)}
        </Col>
        <Col md={6} className="my-5">
          <GatsbyImage image={image} alt="image" />
        </Col>
        <Col md={6} className={itchNumber && "my-5"}>
          <Itch itchNumber={itchNumber} />
        </Col>
        <Col md={6} className="button-wrapper py-5 text-center my-auto">
          <Button text="More" link={`/game/${post.slug}`} />
        </Col>
      </Row>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  &:nth-child(2n) {
    background-color: white;
  }
  .gatsby-image-wrapper {
    border-radius: ${variables.BORDERS.borderRadius};
    box-shadow: ${variables.SHADOWS.lightShadow};
  }
`;

export default Game;
