import React from "react";
import styled from "styled-components";
import variables from "../contants/variables";
import { device } from "../utils/breakpoints";

const Itch = ({ itchNumber }) => {
  if (!itchNumber) return null;

  const url = `https://itch.io/embed/${itchNumber}`;

  return (
    <StyledContainer>
      <iframe
        className="responsive-iframe"
        title="itchEmbed"
        src={url}
        height="225"
        width="552"
        frameBorder="0"
      ></iframe>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: ${variables.BORDERS.borderRadius};
  box-shadow: ${variables.SHADOWS.lightShadow};
  padding-top: calc(200 / 552 * 100%);
  @media ${device.sm} {
    padding-top: calc(131 / 552 * 100%);
  }
  @media ${device.md} {
    padding-top: calc(200 / 552 * 100%);
  }
  @media ${device.xxxl} {
    padding-top: calc(130 / 552 * 100%);
  }
  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  iframe .game_widget_page .first_page .thumb {
    border-radius: ${variables.BORDERS.borderRadius} !important;
  }
`;

export default Itch;
